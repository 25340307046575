import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container } from "@mui/system";
import { Grid, IconButton } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import { StaticImage } from 'gatsby-plugin-image';
import FooterTree from "../components/FooterTree";
import LinkButton from "../components/LinkButton";
import { IButtonItem } from '../Interface';
import { SEO } from "../components/seo";

const Apps: React.FC = () => {
  const data: IButtonItem[] = [
    {
      label: "Splendid",
      variant: "contained",
      icon: <StaticImage alt="cover" src="../images/links_and_apps/splendid.png" height={12} width={20} />,
      url: "https://play.google.com/store/apps/details?id=app.splendid",
      target: "_blank",
    },
    {
      label: "POWA",
      variant: "contained",
      icon: <StaticImage alt="cover" src="../images/links_and_apps/powa.png" height={20} width={20} />,
      url: "https://play.google.com/store/apps/details?id=app.powa",
      target: "_blank",
    },
    {
      label: "Appety",
      variant: "contained",
      icon: <StaticImage alt="cover" src="../images/links_and_apps/appety.png" height={20} width={20} />,
      url: "https://play.google.com/store/apps/details?id=sg.com.appety.app",
      target: "_blank",
    },
    {
      label: "Appety Waiter",
      variant: "contained",
      icon: <StaticImage alt="cover" src="../images/links_and_apps/appety_waiter.png" height={20} width={20} />,
      url: "https://play.google.com/store/apps/details?id=sg.com.appety.waiterapp",
      target: "_blank",
    },
    {
      label: "Appety (App Store)",
      variant: "contained",
      icon: <StaticImage alt="cover" src="../images/links_and_apps/appety.png" height={20} width={20} />,
      url: "https://apps.apple.com/us/app/appety/id1524405547",
      target: "_blank",
    },
    {
      label: "Appety POS",
      variant: "contained",
      icon: <StaticImage alt="cover" src="../images/links_and_apps/appety_pos.png" height={20} width={20} />,
      url: "/404",
      target: "_blank",
    },
    {
      label: "Trampil",
      variant: "contained",
      icon: <StaticImage alt="cover" src="../images/links_and_apps/trampil.png" height={20} width={20} />,
      url: "https://play.google.com/store/apps/details?id=app.trampil",
      target: "_blank",
    },
  ];

  return (
    <Container disableGutters maxWidth="xs">
      <div
        style={{
          backgroundColor: "#FFFFFF",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <StaticImage alt="cover" src="../images/links_and_apps/design2.jpg"/>
          <h2
            style={{
              position: "absolute",
              textAlign: "center",
              width: "100%",
              top: "10vh",
              color: "#FFFFFF",
            }}
          >
            Our Apps
          </h2>
          <Link to="/links">
            <IconButton
              aria-label="delete"
              sx={{ position: "absolute", top: 20, left: 10, color:'common.white' }}
              size="large"
            >
              <ArrowBackIosNew />
            </IconButton>
          </Link>
        </div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          marginTop={-5}
        >
          {data.map((item: IButtonItem, i: number) => (
            <Grid item xs={12} key={i}>
              <LinkButton item={item} />
            </Grid>
          ))}
          <Grid item xs={12} marginTop="50px" marginBottom="20px">
            <FooterTree />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Apps;

export const Head = () => (
  <SEO title="Apps | Garasilabs"/>
)